import React from 'react';
import teacherData from "./Teachers.json";
import teacher1 from "../../../assets/images/teachers/teacher_01.jpg";
import teacher2 from "../../../assets/images/teachers/teacher_02.jpg";
import teacher3 from "../../../assets/images/teachers/teacher_03.jpg";

const Teachers = () => {
    const teacherImages = [teacher1, teacher2, teacher3];

    return (
        <section className="teachers padding-120">
            <div className="container">
                <div className="section-header">
                    <h3>Our Exceptional Faculty</h3>
                    <p>Experience the privilege of learning from accomplished professionals and industry experts. Our faculty members bring real-world experience and cutting-edge insights to the classroom, enhancing your educational journey. With diverse backgrounds and expertise, they provide a comprehensive, industry-relevant education that prepares you for success. Our dedicated mentors are genuinely invested in your growth and development.</p>
                </div>
                <div className="row">
                    {teacherData.map((teacher, index) => (
                        <div className="col-md-4 col-sm-4 col-xs-12" key={index}>
                            <div className="teacher-item">
                                <div className="teacher-image">
                                    <img src={teacherImages[index]} alt={teacher.name} className="img-responsive" />
                                </div>
                                <div className="teacher-content">
                                    <h4><a href="teacher-profile.html">{teacher.name}</a></h4>
                                    <span>{teacher.role}</span>
                                    <ul className="teacher-share">
                                        <li><a href="#"><i className="fab fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fab fa-vimeo" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fab fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fab fa-pinterest" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fa fa-rss" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Teachers;

import React from 'react'
import programsData from "./Programs.json";
import { Button, Col, Row } from 'react-bootstrap';

import programs01 from "../../../assets/images/programs/programs01.jpg";
import programs02 from "../../../assets/images/programs/programs02.jpg";
import programs03 from "../../../assets/images/programs/programs03.jpg";
import programs04 from "../../../assets/images/programs/programs04.jpg";
import programs05 from "../../../assets/images/programs/programs05.jpg";
import programs06 from "../../../assets/images/programs/programs06.jpg";

const Programs = () => {
    const imgData = [programs01, programs02, programs03, programs04, programs05, programs06];
    return (
        <section className="courses section-bg padding-120">
            <div className="container">
                <div className="section-header">
                    <h3>Elevate Your Potential</h3>
                    <p>
                        Elevate Your Potential aims to prepare students for a successful future by providing them with modernized courses, a professional and evidence-based approach, and an immersive educational experience strengthened by innovative teaching methods.</p>
                </div>
                <div className="row">
                    {programsData.map((program, index) => (
                        <div className="col-md-4 col-sm-6 col-xs-12 my-2  key={index}">
                            <div className="course-item h-100">
                                <div className="image">
                                    <img
                                        src={imgData[index]}
                                        alt={`Program_Image ${index + 1}`}
                                        className="img-responsive"
                                    />
                                </div>
                                <div className="content">
                                    <h4>
                                        <a href="course-single.html">{program.title}</a>
                                    </h4>
                                    <p>
                                        {program.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))};

                </div>
                <Row className="justify-content-md-center my-2">
                    <Col lg="2">
                        <Button>
                            <a href="courses" className="text-white">
                                View All Courses
                            </a>
                        </Button>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Programs
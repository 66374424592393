import React from 'react'

import courseData from "./Course.json";
import course01 from "../../../assets/images/courses/course_01.jpg";
import course02 from "../../../assets/images/courses/course_02.jpg";
import course03 from "../../../assets/images/courses/course_03.jpg";
import course04 from "../../../assets/images/courses/course_04.jpg";
import course05 from "../../../assets/images/courses/course_05.jpg";
import course06 from "../../../assets/images/courses/course_06.jpg";
import course07 from "../../../assets/images/courses/course_19.jpg";
import course08 from "../../../assets/images/courses/course_20.jpg";
import course09 from "../../../assets/images/courses/course_21.jpg";
import course10 from "../../../assets/images/courses/course_22.jpg";
import course11 from "../../../assets/images/courses/course_23.jpg";
import course12 from "../../../assets/images/courses/course_24.jpg";


const Course = () => {
    const imgData = [course01, course02, course03, course04, course05, course06, course07, course08, course09, course10, course11, course12];
    return (
        <div>
            <section className="page-header">
                <div className="overlay">
                    <div className="container">
                        <h3>Courses : Empower Your Future</h3>
                        <ul>
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li> - </li>
                            <li>Courses</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="courses padding-120">
                <div className="container">
                    <div className="row">
                        {courseData.map((course, index) => (
                            <div className="col-md-4 col-sm-6 col-xs-12 my-2" key={index}>
                                <div className="course-item h-100">
                                    <div className="image">
                                        <img
                                            src={imgData[index]}
                                            alt={`Course_Image ${index + 1}`} className="img-responsive"
                                        />
                                        <span>
                                            {course.fee === 'Free' ? '' : <sup>$</sup>} {course.fee}
                                        </span>
                                    </div>
                                    <div className="content">
                                        <h4>
                                            <a href="course-single.html">{course.title}</a>
                                        </h4>
                                        <ul>
                                            <li>
                                                <span>
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </span>{" "}
                                                <a href="#">{course['course-place']}</a>
                                            </li>
                                            <li>|</li>
                                            <li>
                                                <span>
                                                    <i className="fa fa-calendar" aria-hidden="true"></i>
                                                </span>{" "}
                                                <a href="#">{course.date} </a>
                                            </li>
                                        </ul>
                                        <p>
                                            {course.desc}
                                        </p>
                                        <div className="bottom">
                                            <ul>
                                                <li>
                                                    <span>
                                                        <i className="fa fa-users" aria-hidden="true"></i>
                                                    </span>{" "}
                                                    <a href="#">{course.number_trainer}</a>
                                                </li>
                                                <li>
                                                    <span>
                                                        <i className="fa fa-comment" aria-hidden="true"></i>
                                                    </span>{" "}
                                                    <a href="#">{course.comment}</a>
                                                </li>
                                            </ul>
                                            <div className="course-rating">
                                                {Array.from({ length: course.rate }, (_, index) => (
                                                    <span key={index}>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </span>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Course

import React from "react";
import Blogs from "./Blog/Blogs";

const Blog = () => {
  return (
    <div>
      <section class="page-header">
        <div class="overlay">
          <div class="container">
            <h3>Blog : Discover a World of Engaging Content</h3>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li> - </li>
              <li>Blog</li>
            </ul>
          </div>
        </div>
      </section>
      <Blogs/>
    </div>
  );
};

export default Blog;

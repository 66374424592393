import React from 'react';
import blogData from "./Blogs.json";
import blog01 from "./../../../assets/images/blog/blog_01.jpg";
import blog02 from "./../../../assets/images/blog/blog_02.jpg";
import blog03 from "./../../../assets/images/blog/blog_03.jpg";
import blog04 from "./../../../assets/images/blog/blog_04.jpg";
import blog05 from "./../../../assets/images/blog/blog_05.jpg";
import blog06 from "./../../../assets/images/blog/blog_06.jpg";

const Blogs = () => {
    const imgData = [blog01, blog02, blog03, blog04, blog05, blog06];
    return (
        <div>
            <section className="blog padding-120">
                <div className="container">
                    <div className="blog-items">
                        <div className="row">
                            {blogData.map((blog, index) => (
                                <div className="col-md-4 col-sm-6 col-xs-12 mt-3 " key={index}>
                                    <div className="blog-item h-100">
                                        <div className="blog-image">
                                            <a href="single.html">
                                                <img
                                                    src={imgData[index]}
                                                    alt={`Blog_Image ${index + 1}`}
                                                    className="img-responsive"
                                                />
                                            </a>
                                        </div>
                                        <div className="blog-content ">
                                            <h4>
                                                <a href="single.html">{blog.title}</a>
                                            </h4>
                                            <p>{blog.desc}</p>
                                        </div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <span className="icon fa-solid fa-calendar-days"></span>
                                                    {blog.date}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <span className="icon fa-regular fa-heart"></span>
                                                    {blog.likes}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <span className="icon fa-regular fa-message"></span>
                                                    {blog.comments} Comments
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Blogs;

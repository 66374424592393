import React from 'react'
import { settings } from "../../utils/settings";
import latestNews from "./LatestNews.json";
import twitterWidget from "./TwitterWidget.json";
import logo from "../../assets/images/logo_02.png";
import footer_post_01 from "../../assets/images/blog/footer_post_01.jpg";
import footer_post_02 from "../../assets/images/blog/footer_post_02.jpg";
import footer_post_03 from "../../assets/images/blog/footer_post_03.jpg";
import gallery_01 from "../../assets/images/sidebar/gallery_01.jpg";
import gallery_02 from "../../assets/images/sidebar/gallery_02.jpg";
import gallery_03 from "../../assets/images/sidebar/gallery_03.jpg";
import gallery_04 from "../../assets/images/sidebar/gallery_04.jpg";
import gallery_05 from "../../assets/images/sidebar/gallery_05.jpg";
import gallery_06 from "../../assets/images/sidebar/gallery_06.jpg";
import gallery_07 from "../../assets/images/sidebar/gallery_07.jpg";
import gallery_08 from "../../assets/images/sidebar/gallery_08.jpg";
const Footers = () => {
  const imgFooterPost = [footer_post_01, footer_post_02, footer_post_03];
  const imgGallery = [gallery_01, gallery_02, gallery_03, gallery_04, gallery_05, gallery_06, gallery_07, gallery_08];
  return (
    <>
      <div className="footer-top">
        <div className="container ">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="footer-item">
                <div className="title d-flex justify-content-center">
                  <a href="/">
                    <img
                      src={logo}
                      style={{
                        height: 100,
                        margin: "-1.3rem 0rem -0.9rem 0rem",
                      }}
                      alt="logo"
                      className="img-responsive"
                    />
                  </a>
                </div>
                <div className="footer-about">
                  <p>
                    Our institution is dedicated to prioritizing academic achievement in order to ensure the future success of our students.
                  </p>
                  <ul className="list-group list-group-flush">
                    <li
                      className="list-group-item bg-transparent"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i className="fa fa-home" aria-hidden="true"></i>
                      </span>{" "}
                      {settings.address}
                    </li>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                      </span>{" "}
                      {settings.phone1} <br /> {settings.phone2}
                    </li>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i
                          className="fa-regular fa-envelope"
                          aria-hidden="true"
                        ></i>
                      </span>{" "}
                      {settings.email1}<br />
                      {settings.email2}
                    </li>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i className="fa fa-globe" aria-hidden="true"></i>
                      </span>{" "}
                      {settings.siteUrl}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="footer-item">
                <h4 className="title ms-4">Latest News</h4>
                <ul className="footer-post">
                  {latestNews.map((item, index) => (
                    <li key={index}>
                      <div className="image">
                        <a href="single.html">
                          <img
                            src={imgFooterPost[index]}
                            alt={`FooterPost_Image ${index + 1}`}
                            className="img-responsive"
                          />
                        </a>
                      </div>
                      <div className="content">
                        <p>
                          <a href="single.html">
                            {item.news}
                          </a>
                        </p>
                        <span>{item.date}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="footer-item">
                <h4 className="title ms-5">Twitter Widget</h4>
                <ul className="twitter-post">
                  {twitterWidget.map((item, index) => (
                    <li>
                      <div className="icon">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </div>
                      <div className="content">
                        <p>
                          <p>#{item.topic}</p>
                          <p>#{item.hashtag1}</p>
                          <p>#{item.hashtag2}</p>
                          <p>#{item.hashtag3}</p>
                        </p>
                      </div>
                    </li>
                  ))}


                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 col-xs-12">
              <div className="footer-item">
                <h4 className="title ms-4">Recent Photos</h4>
                <ul className="photos">
                  {imgGallery.map((item, index) => (
                    <li key={index}>
                      <a href="#">
                        <img
                          src={imgGallery[index]}
                          alt={`RecentGallery_Image ${index + 1}`}
                          className="img-responsive"
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-6 col-xs-12 fw-bold fs-4"
              style={{ fontFamily: "fa-style-family" }}
            >
              ©All Rights Reserved 2023
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <ul className="social-default list-group list-group-horizontal list-group-flush">
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-dribbble" aria-hidden="true"></i>
                  </a>
                </li>
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-google-plus" aria-hidden="true"></i>
                  </a>
                </li>
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-pinterest" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footers

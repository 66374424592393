import React from 'react'
import ServicesData from './Services.json';

const Services = () => {
    return (
        <section className="services padding-120 ">
            <div className="container">
                <div className="row ">
                    {ServicesData.map((item, index) => (
                        <div className="col-md-4 col-xs-12" key={index}>
                            <div className="service-item h-100">
                                <span className={`icon mb-3 mt-3 ${item.icon}`}></span>                                
                                <h4>{item.serviceCaption}</h4>
                                <p>
                                    {item.serviceText}
                                </p>
                                <a href="services.html">
                                    Read More{" "}
                                    <i
                                        className="fa fa-angle-double-right"
                                        aria-hidden="true"
                                    ></i>
                                </a>
                                <br />
                                <br />
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </section>
    )
}

export default Services